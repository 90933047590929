html, body {
  scroll-behavior: smooth;
  font-family: Montserrat, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, span, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

form {
  display: block;
  width: 100%;
}