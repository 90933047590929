.ant-form-item {
  padding: 10px 15px 6px;
  background-color: #F4F4F4;
  border-radius: 25px;
  line-height: 1;
  flex-direction: column;

  .ant-form-item-label {
    padding: 0;
    font-size: 12px;
    line-height: 1;
    text-align: left;

    > label {
      height: auto;
      font-size: inherit;
      line-height: inherit;
      font-weight: 500;
      color: rgba(#2F2F30, .5);
    }
  }

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-horizontal & .ant-form-item-control {
    flex: 0 0 100%;
  }

  nz-input-group {
    padding: 0;
    border: none;
    background-color: transparent;

    input {
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }
  }
}
